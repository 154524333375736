<template>
  <nut-navbar @on-click-back="returnClick()" title="忘记密码"></nut-navbar>
  <div class="content">

    <div class="blank10"></div>
    <div class="blank10"></div>
    <div class="normal-formbox newform">
      <form :model="forgetform">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机号"
                @change="change"
                v-model="forgetform.phone"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入验证码"
                @change="change"
                v-model="forgetform.code"
                @clear="clear"
            />
            <div class="piccode" @click="getIdentifyingCode(true)">
              <img
                  id="picCode"
                  style="height: 30px; width: 80px; cursor: pointer"
                  :src="forgetform.imgSrc"
              />
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机验证码"
                @change="change"
                v-model="forgetform.phonecode"
                @clear="clear"
            />
            <div id="getPhoneCodeDiv" :style="forgetform.phone == '' ? style : ''" class="piccode">
              <nut-button plain id="getPhoneCode" type="info" size="small" @click="getPhoneCode(true)"
                          :disabled="forgetform.phone == '' ?isDisabled : false">发送验证码
              </nut-button>
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="设置新密码"
                @change="change"
                v-model="forgetform.psw"
                type="password"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button plain type="info" class="blue-btn" @click="setPassword()"
                        :loading="isLoading">{{confirmContent}}</nut-button>
          </nut-col>
        </nut-row>
      </form>
    </div>
  </div>
</template>
<script>
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import {Notify} from "@nutui/nutui";

export default {
  name: 'forgetPsw',
  setup() {
    const {proxy} = getCurrentInstance();
    const route = useRoute()
    const router = useRouter()
    //按钮不进入加载状态，可点击
    let isLoading = ref(false);
    //确认按钮文本内容
    let confirmContent = ref("确认");
    //发送验证码div
    const style = {'pointer-events': 'none'};
    //发送验证码按钮
    const isDisabled = ref(true);
    onMounted(
        () => {
          getGraphicVerificationCode();
          timer();
        }
    )
    const forgetform = reactive({
      phone: '',
      psw: '',
      code: '',
      imgSrc: '',
      codeAccount: '',
      phonecode: ''
    })
    // 方法调用
    const change = (value, event) => {
      console.log('change: ', value, event)
    }
    const clear = (value) => {
      console.log('clear:', value);
    };
    const getIdentifyingCode = (value) => {
      getGraphicVerificationCode();
    }
    //获取图形验证码
    const getGraphicVerificationCode = () => {
      const url = "https://service.sview3d.com/service/user/kaptcha";
      axios.post(url).then(
          function (res) {
            forgetform.imgSrc = 'data:image/jpg;base64,' + res.data.img;
            forgetform.codeAccount = res.data.uuid;
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    // 手机验证码
    const getPhoneCode = () => {
      const url = 'https://service.sview3d.com/service/user/getVerificationCode';
      const params = new URLSearchParams();
      params.append('phone', forgetform.phone);
      params.append('code', forgetform.code);
      params.append('account', forgetform.codeAccount);
      //校验手机号
      //校验手机号是否正确
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(forgetform.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }

      axios.post(url, params).then(
          function (res) {
            if (res.data.return == "0" || res.data.return == 0) {
              Notify.success("验证码已发送");
            } else {
              Notify.warn(res.data.msg);
              getGraphicVerificationCode();
            }
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    //调用修改密码接口
    const setPassword = () => {
      //校验参数
      if (forgetform.phone == null || forgetform.phone === '') {
        Notify.warn('手机号不能为空!');
        return false;
      }
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(forgetform.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      if (forgetform.phonecode == null || forgetform.phonecode === '') {
        Notify.warn('手机验证码不能为空!');
        return false;
      }
      if (forgetform.psw == null || forgetform.psw === '') {
        Notify.warn('新密码不能为空!');
        return false;
      }
      isLoading.value = true;
      confirmContent.value = "修改中";
      const url = "https://work.sview3d.com/user/action/setPasswordAction.jsp";
      const params = new URLSearchParams();
      params.append("phone", forgetform.phone);
      params.append("userPassword", forgetform.psw);
      params.append("valcode", forgetform.phonecode);
      axios.post(url, params).then(
          function (res) {
            if (res.data.reback == "true") {
              router.push("/phoneWeb/");
              Notify.success("密码已修改");
            } else {
              proxy.$dialog({
                title: '错误提示',
                content: res.data.msg,
                noCancelBtn: true
              });
              isLoading.value = false;
              confirmContent.value = "确认";
            }
          }
      ).catch(function (error) {
            proxy.$dialog({
              title: '错误提示',
              content: error,
              noCancelBtn: true
            });
            isLoading.value = false;
            confirmContent.value = "确认";
          }
      )
    }
    //返回登录页面
    const returnClick = () => {
      router.push("/phoneWeb/")
    }
    const timer = () => {
      const getPhoneCodeDiv = document.getElementById('getPhoneCodeDiv');
      const getPhoneCode = document.getElementById('getPhoneCode');
      var timer = null;
      getPhoneCode.addEventListener('click', function () {
        //style="pointer-events: none"
        getPhoneCodeDiv.style.pointerEvents = 'none';
        isDisabled.value = true;
        let num = 60;
        getPhoneCode.innerHTML = num + 's后重新发送';
        timer = setInterval(function () {
          num--;
          getPhoneCode.innerHTML = num + 's后重新发送';
          if (num == 0) {
            clearInterval(timer);
            getPhoneCodeDiv.style.pointerEvents = '';
            isDisabled.value = false;
            getPhoneCode.innerHTML = '发送验证码';
          }
        }, 1000)
      })
    }
    return {
      forgetform,
      getIdentifyingCode,
      change,
      clear,
      getGraphicVerificationCode,
      getPhoneCode,
      setPassword,
      returnClick,
      timer,
      style,
      isDisabled,
      isLoading,
      confirmContent
    }
  }
}
</script>
<style scoped>
</style>
